import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Modal from 'src/components/Modal';
import PaymentRequestEditForm from 'src/components/PaymentRequestEditForm';
import { Edit } from 'react-feather';

const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoiceFromPaymentRequest($paymentRequestId: Int, $debugEmailOnly: Boolean) {
    createInvoiceFromPaymentRequest(data: { id: $paymentRequestId, debugEmailOnly: $debugEmailOnly}) {
      data
    }
  }
`;

const PaymentRequestEditModal = ({
  externalInvoiceId,
  paymentRequestId,
  onSuccess,
  children,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [createInvoice, { loading, error }] = useMutation(CREATE_INVOICE_MUTATION);

  const handleSendInvoice = () => {
    setConfirmModalIsOpen(true);
  };

  const handleConfirmSendInvoice = async (debugEmailOnly = false) => {
    try {
      const { data } = await createInvoice({
        variables: { paymentRequestId, debugEmailOnly },
      });
      
      if (data && data.createInvoiceFromPaymentRequest) {
        // Handle successful invoice creation
        console.log('Invoice created successfully:', data.createInvoiceFromPaymentRequest.data);
        onSuccess();
      } else {
        // Handle error case
        console.error('Failed to create invoice');
      }
    } catch (err) {
      console.error('Error creating invoice:', err);
    }
    
    setConfirmModalIsOpen(false);
  };

  return (
    <>
      {!externalInvoiceId && (
        <button onClick={handleSendInvoice} className="btn btn-link p-0 mr-2">
          Send Invoice
        </button>
      )}
      {externalInvoiceId ? (
        <a target="_BLANK" href={`https://www.paypal.com/invoice/p/#${externalInvoiceId}`}>
          {children}
        </a>
      ) : (
        <button
          onClick={() => setModalIsOpen(true)}
          className="btn btn-link p-0 small"
        >
          <Edit width={20} height={20} />
        </button>
      )}

      <Modal
        title="Confirm Send Invoice"
        isOpen={confirmModalIsOpen}
        onClose={() => setConfirmModalIsOpen(false)}
        styleType="small"
        disableForceFocus={true}
      >
        <p>Are you sure you would like to generate an invoice and send an email to the guest?</p>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => setConfirmModalIsOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary mr-2"
            onClick={() => {
              handleConfirmSendInvoice()
            }}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Confirm'}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleConfirmSendInvoice(true)
            }}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'DEBUG ONLY'}
          </button>
        </div>
        {error && <p className="text-danger mt-2">Error: {error.message}</p>}
      </Modal>

      <Modal
        title={`Guest Reservation`}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        styleType="large"
        disableForceFocus={true}
      >
        <PaymentRequestEditForm
          onSuccess={() => onSuccess()}
          paymentRequestId={paymentRequestId}
        />
      </Modal>
    </>
  );
};

export default PaymentRequestEditModal;